@import 'Styles/partials/_variables';

.Button {
  display: inline-flex;
  width: 100%;
  padding: calcrem(6px) calcrem(24px);
  border: 1px solid transparent;
  border-radius: calcrem(8px);
  vertical-align: middle;
  align-items: center;
  justify-content: center;
  color: tc('background', 'dark1');
  background-color: transparent;
  font-size: calcrem(14px);
  font-weight: 400;
  line-height: 1.5;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
  box-shadow 0.15s ease-in-out;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &:disabled {
    cursor: not-allowed;
  }

  &:focus,
  &:active {
    outline: 0;
  }
}

.Button__loader {
  width: calcrem(21px);
  height: calcrem(21px);
}

.Button__primary {
  color: #fff;
  background-color: #024a7c;

  &:not(:disabled):hover {
    background-color: tc('primary', 'black');
  }
}

.Button__secondary {
  color: #024a7c;
  border-color: #024a7c;
  background-color: #fff;

  &:not(:disabled):hover {
    color: tc('primary', 'black');
    border-color: tc('primary', 'black');
  }
}

.Button__isDisabled__primary {
  background-color: tc('background', 'light5');
  cursor: not-allowed;
}

.Button__isDisabled__secondary {
  color: tc('background', 'light5');
  border-color: tc('background', 'light5');
  background-color: #fff;
  cursor: not-allowed;
}