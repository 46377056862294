@import 'Styles/partials/_variables';

.Hamburger {
  display: flex;
  width: 48px;
  height: 48px;
  padding: 0;
  border: 1px solid tc('background', 'light2');
  margin: 0;
  align-items: center;
  justify-content: center;
  z-index: 99;
  background-color: transparent;
  transition: all 0.5s ease-in-out;
  cursor: pointer;

  &:hover {
    border-color: tc('primary', 'gray');
  }

  &:focus {
    outline: none;
  }

  @include media-breakpoint-up(xl) {
    display: none;
  }
}

.Hamburger__inner {
  display: block;
  width: 30px;
  height: 2px;
  position: relative;
  transform-origin: center;
  background-color: tc('primary', 'black');
  transition: all 250ms ease-in-out;

  &::before,
  &::after {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: tc('primary', 'black');
    transition: all 0.5s ease-in-out;
    content: '';
  }

  &::before {
    top: -10px;
  }

  &::after {
    bottom: -10px;
  }
}

.Hamburger_isOpen {
  .Hamburger__inner {
    transform: rotate(45deg);

    &::before {
      top: 0;
      transform: rotate(90deg);
    }

    &::after {
      bottom: 0;
      transform: rotate(90deg);
    }
  }
}
