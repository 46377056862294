@import 'Styles/partials/_variables';

.Container {
  display: flex;
  margin-right: calcrem(16px);
  align-items: center;
}

.Label {
  display: inline-flex;
  margin-right: calcrem(8px);
  font-size: calcrem(14px);
}

.Select {
  min-width: 100px;
  :global {
    .Select {
      &__control {
        background-color: #fff;
        height: 42px;
      }

      &__menu {
        background-color: #fff;
      }

      &__single-value {
        font-size: calcrem(14px);
      }
    }
  }
}
