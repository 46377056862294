@import 'Styles/partials/_variables';

.InputWrapper {
  position: relative;
}

.Input {
  display: block;
  width: 100%;
  height: calcrem(40px);
  padding: 0 calcrem(16px);
  border: 1px solid tc('background', 'light2');
  border-radius: calcrem(8px);
  margin: 0;
  color: tc('background', 'dark2');
  background-color: #fff;
  outline: 0;
  font-size: calcrem(14px);
  font-weight: 400;
  line-height: calcrem(16px);

  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: tc('background', 'light4');
    opacity: 1;
    /* Firefox */
  }
}