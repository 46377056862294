@import 'Styles/partials/_variables';

.Block {
  width: 100%;
  padding: calcrem(24px) calcrem(16px);
  border-radius: calcrem(8px);
  margin: calcrem(16px) 0;
  background-color: #fff;

  @include media-breakpoint-up(md) {
    padding: calcrem(15px);
    margin: calcrem(32px) 0;
  }

  //&:first-of-type {
  //  margin-top: 0 !important;
  //}
}
