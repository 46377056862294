@import 'Styles/partials/_variables';

.scrolled .redux-toastr {
  .top-left,
  .top-right,
  .top-center {
    @include media-breakpoint-down(lg) {
      top: calc(#{$main-nav-height-mobile + 16px});
    }
  }
}

.redux-toastr {
  .top-left,
  .top-right,
  .top-center,
  .bottom-left,
  .bottom-right,
  .bottom-center {
    position: fixed;
    z-index: 1001;
    transition: 300ms ease;
  }

  .top-left,
  .top-right,
  .top-center {
    top: calc(#{$nav-height-mobile} + 16px);

    @include media-breakpoint-up(md) {
      top: calc(#{$main-nav-height} + 16px);
    }
  }

  .bottom-left,
  .bottom-right,
  .bottom-center {
    bottom: 15px;
  }

  .top-left,
  .bottom-left {
    left: 15px;
  }

  .top-right,
  .bottom-right {
    right: 15px;
  }

  .top-center,
  .bottom-center {
    top: 50%;
  }

  .toastr {
    display: flex;
    overflow: hidden;
    width: 290px;
    padding: 20px;
    border-radius: 5px;
    margin-bottom: 10px;
    position: relative;

    &.rrt-success {
      background: tc('actions', 'success');
    }

    &.rrt-info {
      background: tc('actions', 'info');
    }

    &.rrt-warning {
      background: tc('actions', 'warn');
    }

    &.rrt-error {
      background: tc('actions', 'error');
    }

    .rrt-left-container {
      display: none;

      //.rrt-holder {
      //}
    }

    .rrt-middle-container {
      color: #fff;
      font-family: $font-primary;
      font-weight: 700;

      .rrt-title {
        padding-right: 30px;
        margin-bottom: 10px;
        font-size: 14px;
      }

      .rrt-text {
        font-size: 12px;
        font-weight: 500;
      }
    }

    .rrt-right-container {
      position: absolute;
      top: 20px;
      right: 20px;

      .close-toastr {
        padding: 0;
        border: 0;
        margin: 0;
        color: #fff;
        background: none;
        outline: none;
        font-weight: 700;
        cursor: pointer;
        appearance: none;
      }
    }

    .rrt-progress-container {
      display: flex;
      width: 100%;
      height: 5px;
      position: absolute;
      bottom: 0;
      left: 0;

      .rrt-progressbar {
        background: rgba(255, 255, 255, 0.5);
      }
    }
  }
}
