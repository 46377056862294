@import 'Styles/partials/_variables';

.LanguageSelector {
  min-width: 100px;

  .Dropdown__header {
    width: 100px !important;

    border-radius: calcrem(16px) calcrem(16px) 0 0;

    .arrow {
      transform: rotate(-180deg);
    }
  }

  .Dropdown__collapse {
    width: 100px !important;

    border-bottom-left-radius: calcrem(8px);
    border-bottom-right-radius: calcrem(8px);
  }

  .Dropdown__content {
    width: 150px;
    border-top-color: tc('primary', 'black');
    box-shadow: 0 calcrem(10px) calcrem(30px) 0 rgba(0, 0, 0, 0.14);
  }
}

.LanguageSelector__icon {
  margin-right: 10px;

  svg {
    width: 24px;
    height: 24px;
  }
}

.LanguageSelector__name {
  display: inline-block;
}