@import 'Styles/partials/_variables';

.Dropdown {
  width: 100%;
  position: relative;
}

.Dropdown__header {
  display: flex;
  width: 100%;
  height: calcrem($top-nav-height);
  padding: 0 calcrem(20px);
  border: 0;
  position: relative;
  align-items: center;
  justify-content: flex-start;
  z-index: 1;
  color: tc('primary', 'base');
  background-color: #fff;
  outline: 0;
  font-size: calcrem(12px);
  font-weight: 700;
  transition: background 300ms ease;
  cursor: pointer;

  &:active,
  &:focus {
    border: 0;
    outline: 0;
  }

  span {
    overflow: hidden;
    width: 100%;
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  figure {
    transition: transform 300ms ease;
    will-change: transform;
  }
}

.Dropdown_isExpanded {
  .Dropdown__header {

    figure {
      transform: rotate(-180deg);
    }
  }

  .Dropdown__content {
    border-top-color: tc('background', 'light2');
  }
}

.Dropdown__collapse {
  width: 100%;
  border-bottom: 1px solid black;
  padding-top: calcrem($top-nav-height);
  margin-bottom: 50px;
  position: absolute;
  top: 0;
  visibility: visible !important;
  background-color: #fff;
  transition: height 300ms ease;
  will-change: transform;
}

.Dropdown__content {
  overflow: hidden;
  width: 100%;
  max-height: 50vh;
  border-top: 1px solid transparent;
  box-shadow: 0 0 0 0 transparent;

  > * {
    display: flex;
    width: 100%;
    height: 30px;
    padding: 0 calcrem(20px);
    border: 0;
    align-items: center;
    justify-content: flex-start;
    color: tc('primary', 'base');
    background-color: transparent;
    outline: 0;
    font-size: calcrem(12px);
    font-weight: 700;
    transition: color 300ms ease-out;
    cursor: pointer;
    appearance: none;

    &:first-child {
      margin-top: calcrem(10px);
    }

    &:last-child {
      margin-bottom: calcrem(10px);
    }

    &:hover {
      color: tc('primary', 'gray');
      text-decoration: none;
    }

    &:focus {
      outline: 0;
    }
  }
}

.LanguageSelector__icon {
  margin-right: 10px;

  svg {
    width: 24px;
    height: 24px;
  }
}
