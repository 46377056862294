@import 'Styles/partials/_variables';

:global(.scrolled) {
  :local(.Header__top) {
    overflow: hidden;
    height: 0;
  }
}

.Header {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  background-color: #fff;
  box-shadow: 0 calcrem(3px) calcrem(5px) 0 rgba(0, 0, 0, 0.1);
  @media screen and (max-width:768px) {
    height: $nav-height-mobile + $main-nav-height-mobile;
  }
}

.Header__top {
  display: flex;
  width: 100%;
  height: $top-nav-height + 1px;
  border-bottom: calcrem(1px) solid tc('background', 'light2');
  transition: height 250ms ease;

  @media screen and (min-width:768px) {
    display: none;
  }
}

.Header__main {
  display: flex;
  width: 100%;
  height: $main-nav-height;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width:768px) {
    height: $main-nav-height;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
}

.Header__block_right {
  display: flex;
  height: 100%;
  align-items: center;
}

.Header__block_left {
  display: flex;
  height: 100%;
  align-items: center;
}

.Header__languageSelectorWrapper {
  display: flex;
  height: 100%;
  border-left: calcrem(1px) solid tc('background', 'light2');
  align-items: center;
}

