@import 'Styles/partials/_variables';

.Loader {
  width: 100%;
  padding: calcrem(20px);
  border-radius: 10px;
  margin: calcrem(20px) auto 0;
  background-color: #fff;

  @include media-breakpoint-up(md) {
    padding: calcrem(40px);
    margin: calcrem(40px) auto 0;
  }
}
