@import 'Styles/partials/_variables';

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .text#{$infix}-left {
      text-align: left !important;
    }
    .text#{$infix}-right {
      text-align: right !important;
    }
    .text#{$infix}-center {
      text-align: center !important;
    }
  }
}

html {
  min-width: calcrem(325px);
  overflow-y: scroll;
}

body {
  background-color: tc('background', 'light1');
  -webkit-font-smoothing: antialiased;
  font-family: $font-family-base;
  color: tc('background', 'dark2');
}

.button {
  padding: 0;
  border: 0;
  border-radius: 0;
  margin: 0;
  background-color: transparent;
  outline: 0;
  appearance: none;
  text-align: left;

  &:focus,
  &:active {
    outline: 0;
  }
}

a {
  color: inherit;
  // transition: all 150ms ease;

  &:hover {
    color: tc('primary', 'blue');
  }
}
