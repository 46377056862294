@import 'Styles/partials/_fonts';
@import 'Styles/partials/_variables';

@import '~bootstrap/scss/bootstrap-reboot';
@import '~bootstrap/scss/bootstrap-grid';
@import '~bootstrap/scss/utilities/sizing';
@import '~bootstrap/scss/utilities/position';
@import '~bootstrap/scss/_spinners';
@import '~react-redux-toastr/src/styles/animation.scss';

@import 'Styles/partials/_base';

@import 'Styles/components/toastr';
@import 'Styles/components/dropdown';
@import 'Styles/components/pagination';
@import 'Styles/components/select';
