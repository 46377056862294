@import 'Styles/partials/_variables';

:global {
  .ReactModal__Body--open {
    overflow: hidden;
  }
}

.Modal__Overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  background-color: rgba(#000, 0.8);
  overflow: auto;
}

.Modal {

  //bottom: 40px;
  min-width: 300px;

  @include media-breakpoint-up(lg) {
    min-width: 900px;
  }

  @include media-breakpoint-up(xl) {
    min-width: max-content;
  }

  max-width: max-content;
  border: 1px solid rgb(204, 204, 204);
  border-radius: 16px;
  margin: 0 auto;
  position: absolute;
  top: 140px;
  right: 40px;
  left: 40px;
  background: rgb(255, 255, 255);
  outline: none;
}

.Modal_overflow {
  overflow: auto;
}

.Modal_overflowHidden {
  overflow: hidden;
}