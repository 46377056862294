@import 'Styles/partials/_variables';

.ButtonWrapper {
  display: flex;
  align-items: center;

  > * {
    width: auto;
  }
}

.ButtonWrapper--left {
  justify-content: flex-start;
}

.ButtonWrapper--center {
  justify-content: center;
}

.ButtonWrapper--right {
  justify-content: flex-end;
}

.ButtonWrapper--space-between {
  justify-content: space-between;
}

.ButtonWrapper--space-around {
  justify-content: space-around;
}

.ButtonWrapper--space-evenly {
  justify-content: space-evenly;
}
