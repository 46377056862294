@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 700;
  src: local('Nunito Bold'), local('Nunito-Bold'), url('../../Assets/fonts/Nunito-Bold.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 400;
  src: local('Nunito Regular'), local('Nunito-Regular'), url('../../Assets/fonts/Nunito-Regular.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 100;
  src: local('Nunito ExtraLight'), local('Nunito-ExtraLight'),
    url('../../Assets/fonts/Nunito-ExtraLight.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 600;
  src: local('Nunito SemiBold'), local('Nunito-SemiBold'),
    url('../../Assets/fonts/Nunito-SemiBold.ttf') format('truetype');
  font-display: swap;
}
