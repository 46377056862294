@import 'Styles/partials/_variables';

.AppLoader {
  width: 520px;
  margin: 0 auto;
  @media screen and (max-width: 768px) {
    width: 350px;
  }
}

.AppLoader__Message {
  text-align: center;
}

.AppLoader__DotLoader {
  max-width: 100px;
  margin: calcrem(40px) auto 0;
  font-size: calcrem(8px);
}
