@import 'Styles/partials/_variables';

.Checkbox {
  display: inline-flex;
  align-items: center;
  margin: 0;
  position: relative;
  color: tc('primary', 'base');
  font-size: calcrem(15px);
  cursor: pointer;
}

.Checkbox__input {
  width: 0;
  height: 0;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

.Checkbox__checkbox {
  display: flex;
  width: calcrem(24px);
  height: calcrem(24px);
  border: calcrem(1px) solid tc('primary', 'blue');
  border-radius: calcrem(8px);
  margin-right: calcrem(6px);
  position: relative;
  align-items: center;
  justify-content: center;
  transition: background 250ms ease, border-color 250ms ease;
}

.Checkbox__check {
  color: #fff;
  width: calcrem(16px);
  height: calcrem(16px);
  font-weight: 700;

  svg {
    g {
      path {
        fill: currentColor;
      }
    }
  }
}

.Checkbox__input:checked+.Checkbox__checkbox {
  border-color: tc('primary', 'blue');
  background-color: tc('primary', 'blue');
}

.Checkbox__input+.Checkbox__checkbox figure {
  opacity: 0;
  transition: opacity 250ms ease;
}

.Checkbox__input:checked+.Checkbox__checkbox figure {
  opacity: 1;
}