@import 'Styles/partials/_variables';

.NotFound {
  width: 520px;
  margin: 0 auto;
  @media screen and (max-width: 768px) {
    width: 350px;
  }
}

.NotFound__content {
  width: 100%;
  border-radius: calcrem(8px);
  background-color: #fff;

  p {
    &:first-of-type {
      margin-bottom: calcrem(6px);
    }
  }
}

.NotFound__header {
  margin: 0;
  color: tc('primary', 'base');
  font-size: calcrem(24px);
  font-weight: 700;
  text-align: center;

  @include media-breakpoint-up(sm) {
    font-size: calcrem(46px);
  }
}
