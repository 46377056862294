@import 'Styles/partials/_variables';

.PageHeader {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  // width: auto;
  p {
    font-size: calcrem(14px);
    font-weight: 400;
  }

  @include media-breakpoint-up(md) {
    flex-wrap: nowrap;
  }
}

.PageHeader__actions {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;

  @include media-breakpoint-up(md) {
    width: auto;
  }
}

.PageHeader__Dropdown {
  width: 200px;
}
