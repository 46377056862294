@import 'Styles/partials/_variables';

.DateTimePicker {
  display: block;
  width: 100%;
  height: 40px;
  border-radius: calcrem(8px);
  background-color: #fff;

  .MuiInputBase-root {
    width: 100%;
  }

  input {
    width: 100%;
    height: 40px;
    margin: 0;
    padding: 0 calcrem(16px);
    font-size: calcrem(14px);
    font-weight: 400;
    line-height: calcrem(16px);

    &::placeholder {
      color: tc('background', 'light4');
      opacity: 1;
    }
  }

  fieldset {
    border-color: tc('background', 'light2') !important;
    border-radius: calcrem(8px);
    border-width: 1px !important;
  }

  // :global {
  //   .react-datetime-picker {
  //     &__wrapper {
  //       height: 100%;
  //       padding: 0 calcrem(16px);
  //       border: 1px solid tc('background', 'light2') !important;
  //       border-radius: calcrem(8px);
  //       color: tc('primary', 'base');
  //       background-color: #fff;
  //       font-size: calcrem(12px);
  //       font-weight: 400;
  //       line-height: 1.5;
  //     }

  //     &__button {
  //       color: tc('background', 'light5');
  //       transition: color 250ms ease;

  //       &:hover {
  //         color: tc('primary', 'gray');
  //       }

  //       &:focus {
  //         outline: none;
  //       }
  //     }

  //     &__clear-button {
  //       svg {
  //         fill: currentColor !important;
  //       }
  //     }

  //     &__calendar-button {
  //       svg {
  //         stroke: currentColor !important;
  //       }
  //     }
  //   }

  //   .react-calendar {
  //     border: 1px solid tc('background', 'light2');
  //     font-family: $font-primary;

  //     &__tile {
  //       &--active {
  //         background-color: tc('primary', 'gray');
  //       }

  //       &--now {
  //         color: tc('background', 'light1');
  //         background-color: tc('primary', 'gray');
  //       }
  //     }
  //   }
  // }
}