@import 'Styles/partials/_variables';

.AccountDropdown {
  min-width: 150px;

  > button span {
    width: 74px;

    @include media-breakpoint-up(sm) {
      width: 100%;
    }
  }
}
