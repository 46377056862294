@import 'Styles/partials/_variables';

:global {
  .sidebar--is-open {
    overflow: hidden;
  }

  .scrolled {
    :local {
      .Sidebar {
        @include media-breakpoint-down(lg) {
          height: calc(100% - #{$main-nav-height-mobile});
          top: calcrem($main-nav-height-mobile);
        }
      }

      .Sidebar__menuWrapper {
        @include media-breakpoint-down(lg) {
          height: calc(100% - #{$main-nav-height-mobile});
        }
      }
    }
  }
}

.Sidebar {
  min-width: 215px;
  height: calc(100% - #{$nav-height-mobile});
  min-height: 100%;
  padding: calcrem(16px);
  position: fixed;
  top: calcrem($main-nav-height-mobile);  
  left: 0;
  flex-basis: 230px;
  transform: translateX(-300px);
  z-index: 99;
  background: tc('background', 'light2');
  transition: all 500ms ease;

  &::after {
    display: none;
    width: 120vw;
    height: 100%;
    position: fixed;
    top: 0;
    left: 100%;
    opacity: 0.5;
    background: #000;
    transition: opacity 300ms ease;
    content: '';
  }
  @media screen and (max-width: 768px) {
    top:$nav-height-mobile + $main-nav-height-mobile;
  }
  
  @include media-breakpoint-up(xl) {
    width: 200px;
    padding: calcrem(32px) 0 calcrem(32px) calcrem(16px);
    position: static;
    flex-basis: 230px;
    transform: translateX(0);
    z-index: 0;
  }
}

.Sidebar__menuWrapper {
  position: sticky;
  top: 120px;
  overflow-x: hidden;
  overflow-y: auto;
}

.Sidebar_isOpen {
  transform: translateX(0);

  &::after {
    display: block;
  }
}
