@import 'Styles/partials/_variables';

.TextareaWrapper {
  position: relative;
}

.Textarea {
  display: block;
  width: 100%;
  height: 100px;
  padding: calcrem(16px) calcrem(20px);
  border: 1px solid tc('background', 'light2');
  border-radius: calcrem(8px);
  margin: 0;
  color: tc('primary', 'base');
  background-color: #fff;
  outline: 0;
  font-size: calcrem(14px);
  font-weight: 400;
  line-height: 1.5;
  resize: vertical;

  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: tc('background', 'light4');
    opacity: 1;
    /* Firefox */
  }
}