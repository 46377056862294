@import 'Styles/partials/_variables';

.PageHeader {
  width: 100%;
  color: tc('primary', 'base');
  font-size: calcrem(26px);
  font-weight: 700;

  @include media-breakpoint-up(md) {
    font-size: calcrem(34px);
  }

  @include media-breakpoint-up(lg) {
    font-size: calcrem(38px);
  }
}
