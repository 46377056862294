@import 'Styles/partials/_variables';

.Dropdown {
  position: relative;
}

.Dropdown_action {
  position: relative;
  width: 70px;
}

.Dropdown__header {
  display: flex;
  width: 100%;
  height: calcrem($dropdown-height);
  padding: 0 calcrem(10px);
  margin: auto;
  border: 0;
  border-radius: calcrem(8px);
  position: relative;
  align-items: center;
  justify-content: space-between;
  z-index: 1;
  color: #fff;
  background-color: tc('primary', 'black');
  outline: 0;
  font-size: calcrem(12px);
  font-weight: 700;
  transition: all 300ms ease, border-radius 300ms ease;
  cursor: pointer;

  &:active,
  &:focus {
    border: 0;
    outline: 0;
  }

  span {
    overflow: hidden;
    width: 100%;
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .arrow {
    transition: transform 300ms ease;
    will-change: transform;
  }
}

.Dropdown__header_action {
  display: flex;
  width: 70px;
  height: calcrem($dropdown-height);
  padding: 0 calcrem(10px);
  margin: auto;
  border: 0;
  border-radius: calcrem(8px);
  position: relative;
  align-items: center;
  justify-content: space-between;
  z-index: 1;
  color: #fff;
  background-color: tc('primary', 'black');
  outline: 0;
  font-size: calcrem(12px);
  font-weight: 700;
  transition: all 300ms ease, border-radius 300ms ease;
  cursor: pointer;

  &:active,
  &:focus {
    border: 0;
    outline: 0;
  }

  span {
    overflow: hidden;
    width: 100%;
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .arrow {
    transition: transform 300ms ease;
    will-change: transform;
  }
}

.Dropdown_isExpanded {
  .Dropdown__header {
    width: 100%;
    border-radius: calcrem(16px) calcrem(16px) 0 0;

    .arrow {
      transform: rotate(-180deg);
    }
  }

  .Dropdown__header_action {
    width: 130px;
    border-radius: calcrem(16px) calcrem(16px) 0 0;

    .arrow {
      transform: rotate(-180deg);
    }
  }

  .Dropdown__collapse {
    width: 100%;

    border-bottom-left-radius: calcrem(8px);
    border-bottom-right-radius: calcrem(8px);
  }

  .Dropdown__collapse_action {
    width: 130px;

    border-bottom-left-radius: calcrem(8px);
    border-bottom-right-radius: calcrem(8px);
  }

  .Dropdown__content {
    width: 130px;
    border-top-color: tc('primary', 'black');
    box-shadow: 0 calcrem(10px) calcrem(30px) 0 rgba(0, 0, 0, 0.14);
  }
}

.Dropdown__collapse {
  width: 100%;
  padding-top: calcrem($dropdown-height);
  border-radius: calcrem(8px);
  margin-bottom: 50px;
  position: absolute;
  top: 0;
  visibility: visible !important;
  background-color: tc('primary', 'black');
  transition: all 300ms ease, border-radius 300ms ease;
  will-change: transform;
}

.Dropdown__collapse_action {
  width: 100%;
  padding-top: calcrem($dropdown-height);
  border-radius: calcrem(8px);
  margin-bottom: 50px;
  position: absolute;
  top: 0;
  visibility: visible !important;
  background-color: tc('primary', 'black');
  transition: all 300ms ease, border-radius 300ms ease;
  will-change: transform;
}

.Dropdown__content {
  max-height: 50vh;
  width: max-content;
  border-top: 1px solid transparent;
  box-shadow: 0 0 0 0 transparent;
  transition: all 300ms ease, border-top 300ms ease;

  >* {
    display: flex;
    min-width: 100%;
    height: 30px;
    padding: 0 calcrem(13px);
    border: 0;
    align-items: center;
    justify-content: flex-start;
    color: #fff;
    background-color: transparent;
    outline: 0;
    font-size: calcrem(12px);
    font-weight: 700;
    transition: color 300ms ease-out;
    cursor: pointer;
    appearance: none;

    &:first-child {
      margin-top: calcrem(10px);
    }

    &:last-child {
      margin-bottom: calcrem(10px);
    }

    &:hover {
      color: tc('primary', 'gray');
      text-decoration: none;
    }

    &:focus {
      outline: 0;
    }
  }
}