@import 'Styles/partials/_variables';

//:global {
//  .scrolled {
//    :local(.WithSidebarLayout) {
//      @include media-breakpoint-down(lg) {
//        margin-top: calcrem($main-nav-height-mobile);
//      }
//    }
//  }
//}

.WithSidebarLayout {
  margin-top: calcrem($main-nav-height-mobile);

  @media screen and (max-width:768px) {
    margin-top: $nav-height-mobile + $main-nav-height-mobile;
  }
}

.WithSidebarLayout__contentWrapper {
  display: flex;
  width: 100%;
}

.WithSidebarLayout__content {
  width: 100%;
  min-height: calc(100vh - #{$nav-height-mobile});

  @include media-breakpoint-up(md) {
    width: calc(100% - 200px);
    flex: 1 1 auto;
    min-height: calc(100vh - #{$main-nav-height});
  }

  @include media-breakpoint-between(md, md) {
    flex-shrink: 1;
  }
}