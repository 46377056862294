@import 'Styles/partials/_variables';

.expandingDrop,
.expandedDrop {
  z-index: 100;

}

.collapsingDrop {
  z-index: 99;
}
