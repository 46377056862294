@import 'Styles/partials/_variables';

.Iphone_container {
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 500px;

  width: 600px;
  height: 100%;
  margin: 0 auto;

  z-index: 2;
}

.Iphone__header {
  display: flex;
  justify-content: space-between;
  padding-bottom: 5px;
  padding-top: 40px;
  padding-left: 20px;
  padding-right: 20px;
  border: 1px solid #e5e5e5;
  background-color: orange-100;
  font-weight: bold;
  font-size: 1rem;
  /* You can adjust the value according to your desired size */
}

.Iphone__header_container {
  position: absolute;
  top: 0;
  right: 5%;
  height: 94%;
  width: 90%;

  padding-top: 15px;

  z-index: -1;
}

.Iphone__content_container {
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);

  width: 100%;
}

.Iphone_content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  width: 100%;
}

.Rec {
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-name: pulse;
  animation-timing-function: linear;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 5px 0 #ad00004d;
  }

  65% {
    box-shadow: 0 0 5px 13px #ad00004d;
  }

  90% {
    box-shadow: 0 0 5px 13px #ad000000;
  }
}

.pulse_button {
  background-color: red;

  border-radius: 50%;
  border: none;

  width: 50px;
  height: 50px;


  margin: 15px 0 10px 0;
  cursor: pointer;
  animation: pulse 1s infinite;
}

.pulse_button_end {
  background-color: red;

  border-radius: 50%;
  border: none;

  width: 50px;
  height: 50px;


  margin: 15px 0 10px 0;
  cursor: pointer;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}