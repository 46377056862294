@import 'Styles/partials/_variables';

:global {
  @keyframes bouncedelay {
    0%,
    80%,
    100% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    40% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
}

.DotLoader {
  display: flex;
  min-width: 80px;
  align-items: center;
  justify-content: space-evenly;
  font-size: calcrem(14px);
}

.DotLoader__dot {
  width: 2em;
  height: 2em;
  border-radius: 100%;
  background-color: tc('primary', 'gray');
  animation: bouncedelay 1.4s infinite ease-in-out both;
}

.DotLoader__dot_1 {
  animation-delay: -0.32s;
}

.DotLoader__dot_2 {
  animation-delay: -0.16s;
}
