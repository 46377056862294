@import 'Styles/partials/_variables';

.Container {
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
}

.Loader {
  min-width: 300px;
}
