@import 'Styles/partials/_variables';

.Button {
  display: inline-flex;
  width: 100%;
  padding: calcrem(6px) calcrem(24px);
  border: 1px solid transparent;
  border-radius: calcrem(8px);
  vertical-align: middle;
  align-items: center;
  justify-content: center;
  color: tc('background', 'dark1');
  background-color: transparent;
  font-size: calcrem(12px);
  font-weight: 400;
  line-height: 1.5;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &:disabled {
    cursor: not-allowed;
  }

  &:focus,
  &:active {
    outline: 0;
  }
}

.Label {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.Button__loader {
  width: calcrem(21px);
  height: calcrem(21px);
}

.Button__primary {
  color: #fff;
  background-color: tc('primary', 'gray');

  &:not(:disabled):hover {
    background-color: tc('primary', 'black');
  }

  svg {
    height: calcrem(18px);
    width: calcrem(18px);
    fill: #fff;
    stroke: #fff;

    path {
      stroke: #fff;
    }
  }
}

.Button__secondary {
  color: tc('primary', 'gray');
  border-color: tc('primary', 'gray');
  background-color: #fff;

  svg {
    height: calcrem(18px);
    width: calcrem(18px);
    fill: tc('primary', 'gray');
    stroke: tc('primary', 'gray');

    path {
      stroke: tc('primary', 'gray');
    }
  }

  &:not(:disabled):hover {
    color: tc('primary', 'black');
    border-color: tc('primary', 'black');

    svg {
      height: calcrem(18px);
      width: calcrem(18px);
      fill: tc('primary', 'black');
      stroke: tc('primary', 'black');

      path {
        stroke: tc('primary', 'black');
      }
    }
  }
}

.Button__isDisabled__primary {
  background-color: tc('background', 'light5');
  cursor: not-allowed;
}

.Button__isDisabled__secondary {
  color: tc('background', 'light5');
  border-color: tc('background', 'light5');
  background-color: #fff;
  cursor: not-allowed;

  svg {
    height: calcrem(18px);
    width: calcrem(18px);
    fill: tc('background', 'light5');
    stroke: tc('background', 'light5');

    path {
      stroke: tc('background', 'light5');
    }
  }
}