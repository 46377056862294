@import 'Styles/partials/_variables';

.UsersTableCell {
  min-width: 0;
  color: tc('primary', 'base');
  font-size: calcrem(13px);
  padding: 0 5px;

  @include media-breakpoint-up(lg) {
    display: flex;
    width: 100%;
    align-items: center;
  }

  a {
    color: tc('primary', 'black');

    &:hover {
      color: tc('primary', 'gray');
    }
  }
}

.UsersTableCel__inner {
  display: block;
  width: 100%;
}

.UsersTableCel__inner_ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

// .UsersTableCel__inner_ellipsis:hover {
//   padding: 5px;
//   border: 2px solid black;
//   border-radius: 5px;
//   background-color: white;
//   width: auto;
//   overflow: visible;
//   z-index: 11;
//   white-space:normal ;
// }

.UsersTableCell__header {
  color: tc('background', 'light5');
  font-size: calcrem(12.5px);
  font-weight: 700;
}

.UsersTableCell__content {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.UsersTableCell__cellHeader {
  display: block;
  width: 100%;
  color: tc('background', 'light5');
  font-weight: 700;

  @include media-breakpoint-up(lg) {
    display: none;
  }
}

.UsersTableCel__icon {
  font-size: calcrem(20px);
}