@import 'Styles/partials/_variables';

.Container {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.Loader {
  min-width: 150px;
  font-size: calcrem(12px);
}
