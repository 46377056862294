@import 'Styles/partials/_variables';

.TableRow {
  width: 100%;
  padding: calcrem(12px) 0;
  border-radius: 0;
  background-color: tc('background', 'light2');

  &:nth-child(odd) {
    background-color: tc('background', 'light1');

    .button_action {
      background-color: tc('background', 'light1');
    }
  }

  @include media-breakpoint-up(md) {
    padding: calcrem(12px) calcrem(5px);
  }

  @include media-breakpoint-up(xs) {

    >a,
    div {
      margin-right: 0;
      margin-left: 0;
    }
  }

  .CollapseColumn {
    width: 50%;
  }
}

.ContentRow {
  display: flex;
  flex-wrap: wrap;
  padding: calcrem(5px) 0;

  @include media-breakpoint-up(md) {
    padding: calcrem(12px) calcrem(5px);
  }
}

.ContentRow_Total_value {
  display: flex;
  flex-wrap: wrap;
  padding: calcrem(5px) 0;
  background-color: tc('background', 'light3');

  @include media-breakpoint-up(md) {
    padding: calcrem(12px) calcrem(5px);
  }
}

.TableCard {
  border: none;
  overflow: visible !important;
  background-color: tc('background', 'light2');
  border-radius: 0;
  word-wrap: normal;

  &:nth-child(2n) {
    background-color: tc('background', 'light1');

    .button_action {
      background-color: tc('background', 'light1');
    }
  }

  .button_action {
    margin-top: 10px;
    border: none;

    figure {
      transition: transform 300ms linear;

      &:hover {
        transform: scale(1.3);
      }
    }

    background-color: tc('background', 'light2');

    svg {
      width: 15px;
      height: 15px;
    }
  }
}

.Message_Content_ellipsis {
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 350px;
}


.TableRow__header {
  display: none;
  background-color: tc('background', 'white');

  @include media-breakpoint-up(lg) {
    background-color: tc('background', 'white') !important;
    display: block;
    border-bottom-color: tc('background', 'light2');
  }
}

.TableCell {
  color: inherit;
}

.TableCell_Action {
  width: 55px;
}

.TableCell_Action_Settings {
  width: 120px;
}

.TableCell_Action_Reports {
  width: 120px;
}

.TableCell_Action_Schemas {
  width: 120px;
}

.TableCell_Action_Groups {
  width: 120px;
}
// .TableCell_Action_Settings {
//   width: 120px;
// }

.TableCell__message {
  overflow: hidden;
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.TableRow_isNew {
  .TableCell {
    font-weight: 700;
  }
}

.Textarea {
  width: 100%;

  textarea {
    height: auto;
    padding: calcrem(5px) calcrem(10px);
    border: calcrem(1px) solid tc('background', 'light2');
    border-radius: 0;
    background-color: tc('background', 'white');
  }
}

.Panel_Content {
  display: flex;
  flex-direction: row;

  @media screen and (max-width:865px) {
    flex-direction: column;
  }
}

.Panel_Item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 25px;

  padding: 0 5px 0 5px;

  span {
    display: block;
    overflow: hidden;
    max-width: 300px;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: calcrem(13px);

  }
}

.Panel_Item_Analytics {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 25px;

  padding: 0 5px 0 5px;

  span {
    display: block;
    overflow: hidden;
    max-width: 300px;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: calcrem(13px);

  }
}

.Panel_right {
  width: 100%;
  background-color: tc('background', 'white');
  border: 1px solid tc('background', 'light2');

  @media screen and (max-width:865px) {
    width: 100%;
    border-top: 1px solid tc('background', 'light2');
    margin: 0;
  }

  svg {
    filter: invert(17%) sepia(50%) saturate(3353%) hue-rotate(187deg) brightness(96%) contrast(98%);
  }

}

.Panel_left {
  width: 100%;
  background-color: tc('background', 'white');
  border: 1px solid tc('background', 'light2');

  @media screen and (max-width:865px) {
    width: 100%;
  }
}

.Panel_single {
  width: 50%;
  // background-color: tc('background', 'light3');
  border-left: 1px solid tc('background', 'light2');
  height: max-content;

  @media screen and (max-width:865px) {
    width: 100%;
    border-top: 1px solid tc('background', 'light2');
    margin: 0;
  }
}

.ShowProduct_Text {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.ShowProduct_Text_left {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  width: 100%;
}

.ShowProduct {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  padding: 0 16px 0 16px;
  min-height: 50px;

  &:last-child {
    border: 0;
  }

  border-bottom: 1px solid tc('background', 'light2');

  font-size: calcrem(13px);

  button {
    width: 50px;
    height: 2.5rem;

    border-radius: 0.5rem;
    border: none;
    margin-right: 5px;
    background-color: tc('primary', 'navy');
    color: tc('background', 'white');
    z-index: 1;
    outline: 0;
    font-size: 0.75rem;
    font-weight: 700;

    &:hover {
      background-color: tc('primary', 'red');
    }

    svg {
      width: 20px;
      height: 20px;
    }
  }
}

.Download_button {
  padding: 0 !important;
  border: none;
  background-color: transparent;
}


.Table_Order_total_value {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  height: 50px;

  padding: 0 10px 0 10px;

  background-color: tc('background', 'light2');

}

.Tooltip {
  display: flex;
  align-items: center;
  justify-content: center;

  max-width: 400px;

  margin-left: 8px;

  color: black !important;
  background-color: tc('background', 'light6') !important;
  // box-shadow: 0px 1px 5px tc('primary', 'blue') !important;
  border: 1px solid tc('primary', 'blue') !important;
  border-radius: 0.5rem !important;

  font-size: 12px;
  font-weight: 400;
  line-height: 1.5;


  p:last-child {
    margin-bottom: 0;
  }
}