@import 'Styles/partials/_variables';

.DefaultLayout {
  min-height: 100vh;
  border: calcrem(10px) solid tc('background', 'light1');
  position: relative;

  @include media-breakpoint-up(sm) {
    border-width: calcrem(30px);
  }
}

.DefaultLayout__app {
  display: flex;
  height: 100%;
  align-items: center;
}

.DefaultLayout__logout {
  display: flex;
  margin-right: calcrem(16px);
  margin-bottom: calcrem(24px);
  justify-content: flex-end;

  button {
    height: 48px;
  }

  @include media-breakpoint-up(lg) {
    width: auto;
    margin: 9px;
    position: absolute;
    top: 40px;
    right: 220px;
  }
}

.DefaultLayout__LangugageSelector {
  display: flex;
  margin-right: calcrem(16px);
  margin-bottom: calcrem(24px);
  justify-content: flex-end;

  @include media-breakpoint-up(lg) {
    width: auto;
    margin: 9px;
    position: absolute;
    top: 40px;
    right: 40px;
  }
}
