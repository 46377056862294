$theme-colors: ('primary': ('black': #000000,
    'gray': #828788,
    'orange': #f9a52b,
    'base': #1a1b1d,
    'blue': #024a7c,
    'red': #FF6A74),
  'background': ('light1': #f0f1f5,
    'light2': #e0e2ea,
    'light3': #b9bdcf,
    'light4': #acacac,
    'light5': #9194a5,
    'light6': #fdfdff,
    'dark1': #b9bdcf,
    'dark2': #1a1b1d,
    'white': #ffffff),
  'actions': ('error': #f01,
    'info': #186aff,
    'success': #28a745,
    'warn': #d59313,
    'warn1': #d2e1e5,
  ),
);