@import 'Styles/partials/_variables';

.Menu {
  width: 200px;
  // height: 500px;  
  :global {
    // LEVEL 0
    .menu-l0-enter {
      position: absolute;
      transform: translateX(-110%);
    }
    .menu-l0-enter-active {
      transform: translateX(0%);
      transition: all 500ms ease;
    }
    .menu-l0-exit {
      position: absolute;
    }
    .menu-l0-exit-active {
      transform: translateX(-100%);
      transition: all 500ms ease;
    }
    .menu-l0-exit-done {
      display: none;
    }

    // LEVEL 0 to 1
    .menu-l1-enter {
      //position: absolute;
      transform: translateX(110%);
    }
    .menu-l1-enter-active {
      transform: translateX(0%);
      transition: all 500ms ease;
    }
    .menu-l1-exit-active {
      transform: translateX(110%);
      transition: all 500ms ease;
    }
    .menu-l1-exit-done {
      display: none;
    }

    // LEVEL 2
    .menu-l2-enter {
      transform: translateX(110%);
    }
    .menu-l2-enter-active {
      transform: translateX(0%);
      transition: all 500ms ease;
    }
    //.menu-l2-exit {
    //}
    .menu-l2-exit-active {
      transform: translateX(110%);
      transition: all 500ms ease;
    }
    .menu-l2-exit-done {
      display: none;
    }
  }
}

.Menu_admin {
  :global {
    .menu-l1-enter {
      position: absolute;
      transform: translateX(-110%);
    }
    .menu-l1-enter-active {
      transform: translateX(0%);
    }
    .menu-l1-exit {
      position: absolute;
    }
  }
}

.Menu_exchange {
  :global {
    .menu-l1-enter,
    .menu-l1-exit {
      position: absolute;
    }
    .menu-l1-exit-active {
      transform: translateX(-110%);
    }
  }
}

.Menu__items {
  width: calc(100% - 16px);
  margin-right: calcrem(16px);
}

.Menu__item_isActive {
  > i,
  > span {
    color: tc('primary', 'gray');
  }
}
