@import 'Styles/partials/_variables';

.rc-pagination {
  display: flex;
  padding: 0;
  border-radius: 0.25rem;
  margin: 0;
  list-style: none;

  &-item,
  &-prev,
  &-next {
    padding: 6px 5px;
    color: tc('primary', 'base');
    outline: none;
    box-shadow: none;
    font-size: 14px;
    transition: all 250ms ease;

    @include media-breakpoint-up(md) {
      padding: 12px 15px;
    }

    a {
      display: block;
      padding: 0.5rem 0.75rem;
      border: 0;
      margin-left: -1px;
      position: relative;
      color: tc('primary', 'base');
      background-color: transparent;
      outline: none;
      box-shadow: none;
      font-size: 16px;
      font-weight: 700;
      line-height: 1.25;
      transition: color 350ms ease;
      cursor: pointer;
    }

    &:not(.rc-pagination-disabled) {
      a {
        &:hover {
          color: tc('primary', 'gray');
        }
      }
    }
  }

  &-item {
    &-active {
      a {
        border: 0;
        color: tc('primary', 'gray') !important;
        background-color: transparent;
      }
    }
  }

  &-prev {
    a {
      &::after {
        display: block;
        margin-top: -1px;
        content: '\2039';
      }
    }
  }

  &-next {
    a {
      &::after {
        display: block;
        margin-top: -1px;
        content: '\203A';
      }
    }
  }

  &-disabled {
    border: 0;
    opacity: 0.4;
    color: tc('primary', 'base');
    background-color: transparent;
  }
}
