@import 'Styles/partials/_variables';

.InputFeedback {
  display: block;
  width: 100%;
  margin: calcrem(4px) 0 0;
  color: tc('actions', 'error');
  font-size: calcrem(13px);
  font-weight: 700;
}

.InputFeedback_error {
  color: tc('actions', 'error');
}

.InputFeedback_hidden {
  display: none;
}
