@import 'Styles/partials/_variables';

.MenuItem__icon {
  display: inline-block;
  color: tc('background', 'light3');
  transition: all 300ms ease;

  path {
    // stroke: tc('background', 'light3');
  }
}

.MenuItem__image {
  display: inline-block;
  
}

.MenuItem__label {
  display: inline-block;
  color: tc('primary', 'base');
  transition: all 300ms ease;
}

.MenuItem__border {
  border-bottom: calcrem(1px) solid rgba(tc('background', 'light5'), 0.28);
  margin-bottom: calcrem(10px);
}

.MenuItem_isActive {

  >figure,
  >span {
    color: tc('primary', 'gray');

    path {
      // stroke: tc('primary', 'gray');
    }
  }
}

.MenuItem {
  display: flex;
  width: 190px;
  padding: calcrem(10px) 0 calcrem(20px) 0;
  // border-bottom: calcrem(1px) solid rgba(tc('background', 'light5'), 0.28);
  position: relative;
  align-items: center;
  font-size: calcrem(14px);
  font-weight: 600;
  transition: all 300ms ease;

  &:hover {
    text-decoration: none;

    >.MenuItem__icon,
    >.MenuItem__label {
      color: tc('primary', 'blue');

      // svg {
      //   path {
      //     stroke: tc('primary', 'blue')
      //   }
      // }
    }
  }
}

.NotificationCounter {
  display: inline-flex;
  width: calcrem(16px);
  height: calcrem(16px);
  border-radius: 100%;
  margin-left: calcrem(4px);
  align-items: center;
  justify-content: center;
  transform: translateY(-1px);
  color: #fff;
  background-color: tc('actions', 'error');
  font-size: calcrem(11px);
  font-weight: 700;
}