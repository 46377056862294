@import 'Styles/partials/_variables';

.Logo {
  display: flex;
  width: auto;
  height: calcrem($main-nav-height-mobile);
  align-items: center;
  justify-content: center;

  @include media-breakpoint-up(md) {
    height: calcrem($main-nav-height);
  }

  img {
    width: auto;
    height: 55px;

    @include media-breakpoint-up(md) {
      height: 78px;
    }
  }
}
