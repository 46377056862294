@import 'Styles/partials/_variables';

.MultiValueRemove {
  display: flex;
  padding-right: 4px;
  padding-left: 4px;
  align-items: center;
  // color: #fff;
  transition: color 250ms ease;
  cursor: pointer;
}
