@import 'Styles/partials/_variables';

.Select {
  :global {
    .Select {
      &__control {
        //height: 40px;
        padding: calcrem(0px) calcrem(6px);
        min-height: 2.5rem;
        border: 1px solid tc('background', 'light2') !important;
        border-radius: calcrem(8px);
        margin: 0;
        color: tc('primary', 'base');
        background-color: #fff;
        outline: 0;
        box-shadow: none;
        font-size: calcrem(14px);
        font-weight: 400;
        line-height: 1.5;


        &--menu-is-open {
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
        }

        &::placeholder {
          /* Chrome, Firefox, Opera, Safari 10.1+ */
          color: tc('background', 'light4');
          opacity: 1;
          /* Firefox */
        }
      }

      &__dropdown-indicator {
        padding: calcrem(2px);
      }

      &__indicator {
        i {
          font-size: calcrem(14px);
        }

        &-separator {
          display: none;
        }
      }

      &__clear-indicator {
        cursor: pointer;

        &:hover {
          color: tc('primary', 'gray');
        }
      }

      &__menu {
        margin-top: 0;
        margin-bottom: 0;
        color: tc('primary', 'base');
        background-color: #fff;
        border-width: 0px 1px 1px 1px;
        border-color: tc('background', 'light2');
        border-style: solid;
        box-shadow: calcrem(5px) calcrem(9px) calcrem(30px) 0 rgba(0, 0, 0, 0.14);
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
      }

      &__single-value {
        color: inherit;
      }

      &__option {
        font-size: calcrem(12px);

        &--is-selected {
          background-color: tc('primary', 'gray');
        }
      }
    }
  }
}