@import 'Styles/partials/_variables';

.PageContent {
  width: 100%;

  h2 {
    font-size: calcrem(24px);
    font-weight: 700;
  }

  table {
    width: 100%;
    margin-bottom: 1rem;
    color: tc('primary', 'base');

  }

  > table {
    overflow: auto;
  }

  img {
    display: block;
    height: auto;
    max-width: 100%;
  }
}
