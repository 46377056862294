@import 'Styles/partials/_variables';

.Breadcrumbs {
  margin: 0 -4px;
  font-size: calcrem(14px);
  font-weight: 600;
}

.Breadcrumbs__item {
  margin: 0 4px;

  :global(.active) {
    color: tc('primary', 'gray');
    text-decoration-color:tc('primary', 'blue') ;
    :hover {
      color: tc('primary', 'blue');
    }
  }
}
