@import 'Styles/partials/_variables';

.Logo {
  text-align: center;
  // margin: 0 20px 20px 20px;
}

.Logo__logo {
  width: 250px;
  height: auto;

  @include media-breakpoint-up(sm) {
    width: 250px;

    @include media-breakpoint-up(lg) {
      width: 300px;
    }
  }
}